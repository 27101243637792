<template>
  <GmapMap
    :center="markers_current"
    :zoom="14"
    style="width: 100%; height: 150px"
    :options="{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUI: false,
    }"
  >
    <gmap-marker :position="markers_place"> </gmap-marker>
    <gmap-circle
      :center="markers_current"
      :radius="radius ? radius : 500"
      :options="{
        strokeColor: '#62C4E3',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#62C4E3',
        fillOpacity: 0.35,
      }"
    >
    </gmap-circle>
  </GmapMap>
</template>

<script>
export default {
  name: "googlemap",
  props: {
    markers_place: Object,
    markers_current: Object,
    radius: {
      type: Number,
      default: 1000,
    },
  },
};
</script>

<style scoped></style>
