<template>
  <v-container class="pa-0">
    <div style="width: 100%;height: 1px">
      <img style="position: relative;left: 80%;bottom: 1500%" src="@/assets/image/cross.png" v-show="!on_preview" @click="removeImage">
    </div>
    <div class="image-preview text-center" @click="triggle" ref="main_preview" :style="{'background-image':preview?`url(${preview})`:null}">
      <img style="position: relative;top:30%" src="@/assets/image/camera.png" v-show="on_preview">
      <span class="break-line" v-show="on_preview"></span>
      <span style="color: #C4C4C4;position: relative;top:27%" v-show="on_preview">ถ่ายภาพ</span>
    </div>
    <input style="display: none" type="file" accept="image/*" ref="file" @change="tobase64"/>
<!--    <template v-if="preview">-->
<!--      <img :src="preview" width="100%"/>-->
<!--    </template>-->
  </v-container>
</template>

<script>
export default {
  name: "takePhoto",
  data(){
    return{
      image : '',
      image_64 : [],
      preview : '',
      on_preview : true
    }
  },
  methods:{
    triggle(){
      this.$refs.file.click()
    },
    readFile() {
      this.image = this.$refs.file.files[0];
      if (
          this.image.name.includes(".png") ||
          this.image.name.includes(".jpg") ||
          this.image.name.includes(".jpeg")
      ) {
        this.preview = URL.createObjectURL(this.image);
        this.on_preview = false;
      } else {
        this.image = null;
        this.on_preview = true;
      }
      this.$emit('img',this.image_64)
    },
    tobase64: function(event) {
      this.image_64 = [];
      let input = event.target;
      for (let i = 0; i < input.files.length; i++) {
        if (input.files && input.files[i]) {
          let reader = new FileReader();
          reader.onload = e => {
            this.image_64.push({
              image : e.target.result,
            });
          };
          reader.readAsDataURL(input.files[i]);
        }
      }
      this.readFile()
    },
    removeImage(){
      this.image = null;
      this.preview = null;
      this.image_64 = [];
      this.on_preview = true;
    }
  }
}
</script>

<style scoped>

</style>
