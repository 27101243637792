import { render, staticRenderFns } from "./takePhoto.vue?vue&type=template&id=eff24846&scoped=true&"
import script from "./takePhoto.vue?vue&type=script&lang=js&"
export * from "./takePhoto.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eff24846",
  null
  
)

export default component.exports