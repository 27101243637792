var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GmapMap',{staticStyle:{"width":"100%","height":"150px"},attrs:{"center":_vm.markers_current,"zoom":14,"options":{
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUI: false,
  }}},[_c('gmap-marker',{attrs:{"position":_vm.markers_place}}),_c('gmap-circle',{attrs:{"center":_vm.markers_current,"radius":_vm.radius ? _vm.radius : 500,"options":{
      strokeColor: '#62C4E3',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#62C4E3',
      fillOpacity: 0.35,
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }